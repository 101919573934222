import { ErrorHandler, Injectable } from "@angular/core";
import { Logger } from "./logger.service";
import { LogLevel } from "@Assistant/SystemTypes/Logging/LogLevel";

@Injectable({ providedIn: "root" })
export class VituExceptionHandlerService implements ErrorHandler {

    constructor(
        private logger: Logger,
    ) {}

    private originalHandler = new ErrorHandler();

    handleError(error: any) {

        this.originalHandler.handleError(error);

        this.logger.log({
            logLevel: LogLevel.Error,
            eventName: "BROWSER_ERROR",
            payload: this.buildErrorPayload(error),
        });
    }

    private buildErrorPayload(error: Error) {

        return {
            text: error.toString(),
            stack: error instanceof Error
                ? error.stack
                : null,
        };
    }
}
